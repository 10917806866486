import React, { useCallback } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useMetrika } from '~/hooks/useMetrika'
import { useGetProgramStatusText } from '~/hooks/useGetProgramStatusText'
import { useTypedSelector } from '~/store/redux-store'
import { AnimatedContainer } from '@/MultiStepModal/components/AnimatedContainer'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { useIntl } from 'react-intl'
import { Expanded } from '@/Expanded'
import { Button } from '@/Button'
import { Container } from './common/stylesCommon'
import { Help } from './common/Help'
import { ProgramNumberInput } from './common/ProgramNumberInput'
import { useAuthorization } from '../hooks/useAuthorization'
import { Meta, Row } from './common/Meta'
import { SignupNotice } from './common/SignupNotice'

export function AuthorizationX5() {
  const intl = useIntl()
  const { reachGoal } = useMetrika()

  const selectedProgram = useTypedSelector(({ process }) => process.selectedProgram)
  const program = useGetPrograms(selectedProgram as string)

  const { hasEntry, entryName, entryStatus, details } = program
  const { icon, name, inputMask, caption, signupNotice } = details

  const {
    phoneNumber,
    handlePhoneInput,
    resetStatus,
    doAuthorization,
    replacementMode,
    checkNumberValidity,
    authorizationProcessStatus,
    isAuthorizeLoading,
    isReplaceNumberLoading,
  } = useAuthorization(selectedProgram!)

  const cardNr = entryName ?? (intl.messages[`app.program.no_entry_name`] as unknown as string)
  const cardStatusText = useGetProgramStatusText(selectedProgram!)

  const metaRows: Row[] = [
    {
      param: intl.messages[`app.program.x5_card_name_prefix`] as unknown as string,
      value: cardNr,
    },
  ]
  if (cardStatusText) {
    metaRows.push({ param: intl.messages[`app.program.status_prefix`] as unknown as string, value: cardStatusText })
  }

  const isAuthFailed = entryStatus === 'E'
  const isAuthSuccess = entryStatus === 'I'

  const isSuccess = authorizationProcessStatus === 'isSuccess'
  const hasNoCurrentProcessStatus = authorizationProcessStatus === 'void'
  const isServerError = authorizationProcessStatus === 'isError'

  const isNumberChanged = phoneNumber !== entryName
  const isNumberValid = checkNumberValidity(phoneNumber)

  const isAuthProcessCompleted = isSuccess && !isAuthFailed

  const showCheckEnteredNumberHelp = !entryName && hasNoCurrentProcessStatus
  const showSuccessHelp = isAuthSuccess && isSuccess

  const isButtonClickable = isNumberValid && isNumberChanged && !isAuthorizeLoading && !isReplaceNumberLoading

  const showButton = !(isAuthSuccess && isSuccess)
  const showNotice = Boolean(signupNotice) && !hasEntry

  let buttonText: string
  switch (true) {
    case replacementMode:
      buttonText = intl.messages['app.program.authorization.X5_change_number'] as unknown as string
      break
    default:
      buttonText = intl.messages['app.program.authorization.x5_authorize_program'] as unknown as string
  }

  const authorizationHandler = useCallback(() => {
    if (!isButtonClickable) return
    reachGoal({ target: 'doAuthorization', params: { doAuthorization_Program: name } })
    doAuthorization(phoneNumber)
  }, [phoneNumber, doAuthorization, isButtonClickable, name, reachGoal])

  const buttonClickHandler = isAuthProcessCompleted ? resetStatus : authorizationHandler

  return (
    <AnimatedContainer animationKey="Authorization">
      <Meta icon={icon} title={name} rows={metaRows} />
      <Container>
        <ProgramNumberInput
          label={caption}
          mask={inputMask}
          value={phoneNumber}
          handleChange={handlePhoneInput}
          isError={isAuthFailed}
          isAutoFocus={false}
        />
        <AnimatePresence mode="wait">
          {showCheckEnteredNumberHelp && <Help key="hint" type="hint" program={program} isX5 />}
          {showSuccessHelp && <Help key="resultIsOK" type="resultIsOK" program={program} isX5 />}
          {isAuthFailed && <Help key="isAuthFailed" type="isAuthFailed" program={program} isX5 />}
          {isServerError && <Help key="isServerError" type="isServerError" program={program} isX5 />}
          {showButton && <Expanded key="expanded" />}
          {showButton && (
            <ButtonContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.2 }}
            >
              <Button
                key="doAuthorizationButton"
                isDisabled={!isButtonClickable}
                onClick={buttonClickHandler}
                variant={replacementMode ? 'outlined' : 'contained'}
                isFullWidth
              >
                {buttonText}
              </Button>
            </ButtonContainer>
          )}
        </AnimatePresence>
        {showNotice && <SignupNotice text={signupNotice} />}
      </Container>
    </AnimatedContainer>
  )
}

const ButtonContainer = styled(motion.div)`
  width: 100%;
  display: flex;
`
