import parse, { DOMNode, domToReact } from 'html-react-parser'
import Link from 'next/link'

export const parseHtmlStringToComponents = (htmlString: string, linkClickHandler?: () => void) => {
  return parse(htmlString, {
    replace(domNode) {
      if (domNode.type === 'tag' && domNode.name === 'a') {
        const href = domNode.attribs.href || ''

        if (href.startsWith('/')) {
          return (
            <Link onClick={linkClickHandler} href={href}>
              {domToReact(domNode.children as DOMNode[])}
            </Link>
          )
        }
      }
    },
  })
}
