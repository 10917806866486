import styled from 'styled-components'
import { colors } from '~/lib/theme'
import { IconWireFrame, IconWireFrameType } from '@/icon/IconWireFrame'
import { getPluralCurrency } from '~/lib/utils/getCurrencyName'
import { ProgramModel } from '~/models'
import { motion } from 'framer-motion'
import { BtnActionsStyle } from '@/BtnActionsStyle'
import { IntlShape, useIntl } from 'react-intl'
import { parseHtmlStringToComponents } from '~/lib/utils/parseHtmlStringToComponents'

type SectionType = 'hint' | 'resultIsOK' | 'resultIsDelayed' | 'isAuthFailed' | 'willPaySoonInfo' | 'isServerError'

type Section = {
  icon: IconWireFrameType
  color: string
  text: string
}

function getSectionData(
  sectionType: SectionType,
  program: ProgramModel,
  { formatMessage, messages }: IntlShape,
  isX5?: boolean
): Section {
  const { name, currencyNames, signupResultMessage, hint } = program.details

  const resultMessage = (signupResultMessage || '')
    .replace(':Название программы:', name)
    .replace(':валюта в Р.п. мн. ч.:', getPluralCurrency(currencyNames, 0))

  const sectionData: Record<SectionType, Section> = {
    hint: {
      icon: 'Warning',
      color: colors.gray,
      text: hint,
    },
    resultIsOK: {
      icon: 'RoundCheck',
      color: colors.green,
      text: isX5 ? (messages[`app.program.authorization.X5_success`] as unknown as string) : resultMessage,
    },
    resultIsDelayed: {
      icon: 'CalendarLate',
      color: '#E1BC29',
      text: resultMessage,
    },
    isAuthFailed: {
      icon: 'Warning',
      color: colors.red,
      text: isX5
        ? (messages[`app.program.authorization.x5_enter_correct_id`] as unknown as string)
        : formatMessage({ id: 'app.program.authorization.enter_correct_id' }, { name }),
    },
    willPaySoonInfo: {
      icon: 'Help',
      color: colors.gray,
      text: messages[`app.program.authorization.${isX5 ? 'x5_will_pay_soon' : 'will_pay_soon'}`] as unknown as string,
    },
    isServerError: {
      icon: 'Warning',
      color: colors.red,
      text: formatMessage({ id: 'app.program.authorization.error_server' }, { name }),
    },
  }
  return sectionData[sectionType]
}

interface Props {
  type: SectionType
  program: ProgramModel
  isX5?: boolean
}

export function Help({ type, program, isX5 }: Props) {
  const intl = useIntl()
  const { color, text, icon } = getSectionData(type, program, intl, isX5)

  return (
    <Container
      $backgroundColor={color}
      key={type}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
    >
      <IconContainer>
        <IconWireFrame icon={icon} color={color} size={16} strokeWidth={2} />
      </IconContainer>
      <Text>{parseHtmlStringToComponents(text)}</Text>
    </Container>
  )
}

const Container = styled(motion.div)<{ $backgroundColor: string }>`
  display: flex;
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  position: relative;
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: -1;
    opacity: 0.1;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
  }
`

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  flex-shrink: 0;
  margin-right: 8px;
  margin-top: 3px;
`

const Text = styled.span`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  & a {
    font-weight: 700;
    color: ${({ theme }) => theme.brand_color};
    ${BtnActionsStyle};
  }

  & strong {
    font-weight: 700;
  }
`
